<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseState from '/~/components/base/state/base-state.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import { usePurchases } from '/~/composables/purchases'
import ArchivedPurchases from './drawer-e-purchases-archived.vue'
import CurrentPurchases from './drawer-e-purchases-current.vue'

export default {
  name: 'drawer-e-purchases',
  components: {
    CurrentPurchases,
    ArchivedPurchases,
    BaseButton,
    BaseTabs,
    BaseTab,
    BaseState,
    BaseAsidePage,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
  },
  setup() {
    const route = useRoute()
    const { isPurchasesLoading, isPurchasesEmpty, fetchCurrentPurchases } =
      usePurchases()

    const tab = ref(route.params.tab ?? 'current')
    const loading = ref(false)

    return {
      tab,
      loading,
      isPurchasesLoading,
      isPurchasesEmpty,
      fetchCurrentPurchases,
    }
  },
  created() {
    emitter.on('menu:epurchases:set-tab', this.setTab)
  },
  beforeDestroy() {
    emitter.off('menu:epurchases:set-tab', this.setTab)
  },
  methods: {
    setTab(tab) {
      this.tab = tab
    },
    async updatePurchases() {
      if (this.loading) {
        return
      }

      try {
        this.loading = true

        await this.fetchCurrentPurchases()
      } catch (error) {
        console.error('drawer', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<template>
  <base-aside-page title="My ePurchases" no-padding>
    <template #action>
      <base-button
        icon="plain/reload"
        :class="[loading && 'animate-spin']"
        @click="updatePurchases"
      />
    </template>

    <base-tabs v-if="!isPurchasesEmpty" v-model="tab" class="h-full">
      <base-tab name="Current" value="current" keep-alive>
        <current-purchases :to="to" />
      </base-tab>
      <base-tab name="Archived" value="archived" keep-alive>
        <archived-purchases />
      </base-tab>
    </base-tabs>

    <base-state
      v-else-if="!isPurchasesLoading"
      title="You have no ePurchases"
      image="/empty/empty-epurchases.svg"
      class="min-h-full p-5"
    >
      <p>
        Access and manage your digital eGift Cards and eVouchers, ready for
        instant in-store redemption.
      </p>
    </base-state>
  </base-aside-page>
</template>
